<template>
    <div class="w-full">
        <template v-for="(response, responseIndex) in responses">
            <v-alert v-if="response.message" :key="'response_' + responseIndex" color="success">
                <template v-slot:title>
                    <div class="h-full flex items-center justify-between">
                        <span class="flex justify-items-start">
                            <div class="text-primary-500 text-base aa pl-8 py-2">
                                <v-icon class="fa fa-check-circle" />
                            </div>

                            <v-heading :size="4">
                                {{ response.message }}
                            </v-heading>
                        </span>

                        <v-button color="dark" class="self-start" @click.stop="responses.splice(responseIndex, 1)">
                            <v-icon class="fa fa-times" />
                        </v-button>
                    </div>
                </template>
            </v-alert>
        </template>

        <template v-for="(error, errorIndex) in errors">
            <template v-if="error">
                <v-alert :key="'error_' + errorIndex" color="danger">
                    <template v-slot:title>
                        <div class="h-full flex items-center justify-between">
                            <div class="flex flex-col">
                                <template v-if="error.data.errors">
                                    <div class="flex flex-row">
                                        <div class="text-red-500 text-base aa pl-8 py-2">
                                            <v-icon class="fa fa-exclamation-circle" />
                                        </div>

                                        <ul>
                                            <template v-for="[field, messages] of Object.entries(error.data.errors)">
                                                <template v-for="(message, index) in messages">
                                                    <li :key="'error_message_' + field + index" class="flex justify-items-start">
                                                        <v-heading :size="4">
                                                            {{ message }}
                                                        </v-heading>
                                                    </li>
                                                </template>
                                            </template>
                                        </ul>
                                    </div>
                                </template>

                                <template v-else>
                                    <span :key="'error_message_' + errorIndex" class="flex justify-items-start">
                                        <v-heading :size="4" color="danger" class="flex items-center">
                                            <v-icon class="fa fa-exclamation-circle" />
                                        </v-heading>

                                        <v-heading :size="4" color="danger">
                                            {{ error.data.message }}
                                        </v-heading>
                                    </span>
                                </template>
                            </div>

                            <v-button color="dark" class="self-start" @click.stop="errors.splice(errorIndex, 1)">
                                <v-icon class="fa fa-times" />
                            </v-button>
                        </div>
                    </template>
                </v-alert>
            </template>
        </template>
    </div>
</template>

<script>
// import _ from "lodash";

export default {
    props: {
        location: {
            type: String,
            required: false,
            default: () => "default",
        },
    },
    data() {
        return {
            responses: [],
            errors: [],
            form: {
                name: "",
            },
            alertLimit: 3,
        };
    },
    created() {
        this.$eventBus.$on("error", (error) => {
            // this.errors.push(error.response);
            // this.errors = _.uniq(this.errors);

            // // Limit error alerts shown
            // if (this.errors.length > this.alertLimit) {
            //     this.errors.shift();
            // }
            let message = "System Error";

            if (error.message) {
                message = error.message;
            }

            if (error.response) {
                if (error.response.message) {
                    message = error.response.message;
                }

                if (error.response.data) {
                    if (error.response.data.errors && Object.keys(error.response.data.errors).length) {
                        const { errors } = error.response.data;
                        if (Array.isArray(errors) && errors.length > 0) {
                            [message] = errors;
                        }
                    }

                    if (error.response.data.message) {
                        message = error.response.data.message;
                    }
                }
            }

            this.$toast({
                text: message,
                type: "error",
            });
        });

        this.$eventBus.$on("validation-error", (error) => {
            // this.errors.push(error.response);
            // this.errors = _.uniq(this.errors);

            // // Limit error alerts shown
            // if (this.errors.length > this.alertLimit) {
            //     this.errors.shift();
            // }

            if (error.response.data.message) {
                this.$toast({ text: error.response.data.message, type: "error" });
            }
        });

        this.$eventBus.$on("response", (response) => {
            if (response.data.meta && response.data.meta.message) {
                // this.responses.push({
                //     message: response.data.meta.message,
                // });

                this.$toast({ text: response.data.meta.message, type: "success" });
                return;
            }

            if (response.data && response.data.message) {
                // this.responses.push({
                //     message: response.data.message,
                // });

                this.$toast({ text: response.data.message, type: "success" });
            }
        });
    },
};
</script>
